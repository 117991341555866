import { processResults } from '~/javascript/utilities/autocompleter';

export const initializeRxSelect = ({ field, includeClasses, resultsFormatter, autocompleteUrl }) => {
  return $j(field).select2({
    width: 200,
    allowClear: false,
    minimumInputLength: 1,
    ajax: {
      url: autocompleteUrl,
      delay: 250,
      data: (params) => {
        let p = { entered: params.term };

        if(includeClasses) {
          p['include_classes'] = includeClasses;
        }

        return p;
      },
      dataType: 'json',
      processResults: (data, params) => {
        return processResults(data, params, resultsFormatter);
      }
    }
  });
};

