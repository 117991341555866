import _ from 'underscore';


export const processResults = (data, params, formatter) => {
  const results = formatter(data.results);

  const filteredResults = _.filter(results, result => {
    const tokens = result.text.toLowerCase().split(' ');
    const terms = params.term.toLowerCase().split(' ');

    return _.all(terms, term => {
      return _.any(tokens, token => token.startsWith(term))
    });
  });

  return {
    results: filteredResults
  }
};